import React from 'react';
import { UserOutlined,PoweroffOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import Agreement from '../Component/agreement';
import { Modal,Input, Col, Row, Select, InputNumber, DatePicker, AutoComplete, Cascader ,Button,Card,message,Checkbox} from 'antd';

import axios from '../public/axios'
import Indexs from '../../src/page/index'
import './register.scss'
const { Option } = Select;
const { Search } = Input;

class Register extends React.Component {
        constructor(props){
            super(props)
            this.state={
                phone:'',
                code:'',
                password:'',
                email:'',
                Check:'',
                visible: false ,
                loadings: [],
                Selectvalue:'+86',
                entervalue:'获取验证码',
                timer:null
            }
        }
        componentDidMount(){
          document.title="註冊"
       }
      //注册按钮
        enterLoading = index => {
            if(this.state.phone==''){
                return   message.error('請輸入妳的手機號');
            }
            if(this.state.code==''){
                return   message.error('請輸入驗證碼');
            }
            if(this.state.password==''){
                return   message.error('請輸入妳的密碼');
            }
            if(this.state.email==''){
                return   message.error('請輸入妳的郵箱');
            }
            if(this.state.Check==''){
                return   message.error('請先勾選服務協議');
            }
            const newLoadings = [...this.state.loadings];
            newLoadings[index] = true;
            this.setState({
              loadings: newLoadings,
            });
            axios.post('/v1/web/register_account',{
                "mobile":this.state.phone,
                "area_code": this.state.Selectvalue,
                "avatar": "https://......",
                "password": this.state.password,
                "email": this.state.email,
                "mobile_code":this.state.code
            }).then(res=>{
                newLoadings[index] = false;
                this.setState({ loadings: newLoadings });
                if(res.code==0){
                    message.success('註冊成功');
                    this.goto('/login')
                }else{
                    message.error(res.msg);
                }
            })

          
        };
        //页面调整
        goto(url){
            this.props.history.push(url);
        }
        //sele选择
        handleChange = value =>{
          this.setState({ Selectvalue: value });
          console.log(value)
        }
        handleonSearch = value =>{
            if(this.state.phone==''){
                return   message.error('請輸入妳的手機號');
            }

            if(this.state.entervalue!=='获取验证码'){
                return
            }
            const newLoadings = [...this.state.loadings];
            newLoadings[1] = true;
            this.setState({
              loadings: newLoadings,
            });
            axios.post('/v1/web/register_send_sms',{
                "mobile": this.state.phone,
                "area_code":this.state.Selectvalue
           }).then(res=>{
                newLoadings[1] = false;
                this.setState({ loadings: newLoadings });
                    let num = 60;
                    let timer =   setInterval(() => {
                        num--;
                        console.log(num)
                        this.setState({ entervalue: num });
                        if(num<=0){
                            clearInterval(timer)
                            this.setState({ entervalue:'获取验证码' });
                        }
                    }, 1000);
                    this.setState({ timer: timer });
           })
           
            console.log(value)
        }
        componentWillUnmount(){
            clearInterval(this.state.timer);
        }
        agreement = index =>{
            this.setState({
                visible: true,
            });
        }
        handleCancel = index =>{
            this.setState({
                visible: false,
            });
        }
        render() {
            const { loadings } = this.state;
          return (
             
            <div className="Login" style={{'marginBottom':'100px','marginTop':'100px'}}>
                <Card
                  className="Login-card"
                >
                 <h3 className="center">註冊賬號 </h3>
                 <br></br>
                 <span className="loginGroup">
                 <Input.Group compact c >
                    <Input className="Loginipt" disabled onChange={(e)=> this.setState({ phone: e.target.value })}  placeholder="請輸入郵箱" />
                  </Input.Group>
                  </span>
                 {/* <Input  className="Loginipt" size="large" placeholder="name" prefix={<UserOutlined />} /> */}
                 <br></br>
                
                 <Search
                 disabled
                    loading={loadings[1]}
                    className="Loginipt LoginSearch"
                    placeholder="請輸入驗證碼"
                    enterButton={this.state.entervalue}
                    size="large"
                    onSearch={this.handleonSearch}
                    onChange={(e)=> this.setState({ code: e.target.value })}
                    />
                     <br></br>
                 <br></br>
                 <Input.Password className="Loginpsd" disabled onChange={(e)=> this.setState({ password: e.target.value })} placeholder="請輸入密碼" />
                 <br></br>
                 <br></br>
                 <Input className="Loginpsd" disabled onChange={(e)=> this.setState({ email: e.target.value })} placeholder="確認密碼" />
                 <br></br>
                 <br></br>
                 <p><Checkbox onChange={(e)=> this.setState({ Check: e.target.value })} >已閱讀並同意</Checkbox> <span onClick={() => this.agreement(0)}>《XPay服務協議》</span></p>
                
                
                 <Button type="primary" disabled className="Loginbtn" loading={loadings[0]} onClick={() => this.enterLoading(0)}>
                    服務維護中
                 </Button>
                 <br></br>
                 <br></br>
                 <Button type="default" style={{'border':'none','boxShadow':'none'}} className="Loginbtn"  onClick={() => this.goto('/Login')}>
                      已有賬號？立即登錄 >
                 </Button>
                 </Card>
                 <Modal
                     width="80%"
                    title="《XPay服務協議》"
                    visible={this.state.visible}
                    footer={null}
                    maskClosable
                    centered
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                    >
                    <div style={{'height':'400px','overflow':'scroll'}}>
                            <Agreement/>
                    </div>
                    </Modal>
                    
            </div>
           
          );
        }
      }
    
export default withRouter(Register);