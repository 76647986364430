import React, { Component } from 'react';
import { ThunderboltOutlined } from '@ant-design/icons';

import { Tabs,Input,Select,Button,InputNumber,Modal,message} from 'antd';
import getBrowserInfo from 'get-browser-info';

import axios from '../public/axios'
import './trade.scss'

const { TabPane } = Tabs;
const { Option } = Select;
const clientInfo = getBrowserInfo()
class Trade extends React.Component {
    constructor(props){
        super(props)
        this.state={
            loadings: [], //loading
            tabsoffer:'buy',
            Selectvalue:'price',
            number:0,
            moveplaceholder:'請輸入妳的購買總金額',
            modevalue:'CNY',
            usdtCny:'',
            usdNmuber:0,
            visible: false,
            buyoffer:0,
            selloffer:0,
            timer:null,
            timestamp:''
        }
    }
   
    componentDidMount(){
        console.log(clientInfo)
        document.title="法幣交易"
        axios.get('/v1/web/v2/cert_kyc_status').then(res=>{
            if(res.code==0){
                this.getprice()
                let timer = setInterval(()=>{ 
                    this.getprice()
                 },30000)
                 this.setState({ timer: timer });
            }else{
                this.warning()
            }
        })
     
    }
    componentWillUnmount(){
        clearInterval(this.state.timer);
    }
    getprice(){
        // console.log('2222')
        axios.get('/v1/web/otc/price').then(res=>{
            if(res.code==0){
                this.setState({ buyoffer: res.data.buy});
                this.setState({ selloffer: res.data.sell });
                this.setState({ timestamp: res.data.timestamp });
                
                // console.log(res.data)
            }
        })
    }
    //提示實名認證
      warning() {
        let that = this
        Modal.warning({
          centered:true,
          title: '提示',
          content: '請先完成實名認證信息',
          okText:'去認證',
          onOk() {
            that.props.history.push('/kycCertification');
            window.location.reload() 
          },
        });
      }
      //tab切換
    callback= value =>{
        if(value=='1'){
            this.setState({ tabsoffer: 'buy' });
            if(value=='price'){
                this.setState({ moveplaceholder: '請輸入妳的購買總金額' });
            }else{
                this.setState({ moveplaceholder: '請輸入妳的購買總數量' });
            }
            
        }else{
            this.setState({ tabsoffer: 'sell' });
           
            if(value=='price'){
                this.setState({ moveplaceholder: '請輸入妳的出售總金額' });
            }else{
                this.setState({ moveplaceholder: '請輸入妳的出售總數量' });
            }
        }
       
    }
     //sele選擇
     handleChange = value =>{
        this.setState({ Selectvalue: value });
        if(this.state.tabsoffer=='buy'){
            if(value=='price'){
                this.setState({ moveplaceholder: '請輸入妳的購買總金額' });
                this.setState({ modevalue: 'CNY' });
                this.setState({usdtCny:''})
            }else{
                this.setState({ moveplaceholder: '請輸入妳的購買總數量' });
                this.setState({ modevalue: 'USDT' });
                this.setState({usdNmuber:''})
            }
        }else{
            if(value=='price'){
                this.setState({ moveplaceholder: '請輸入妳的出售總金額' });
                this.setState({ modevalue: 'CNY' });
                this.setState({usdtCny:''})
             }else{
                this.setState({ moveplaceholder: '請輸入妳的出售總數量' });
                this.setState({ modevalue: 'USDT' });
                this.setState({usdNmuber:''})
             } 
        }
      }
      //限制輸入
      InputNumberchange = value =>{
        var reg = /^[0-9]+.?[0-9]*$/;
        if (reg.test(value)) {
            let tlnumber = value.toString().match(/^\d+(?:\.\d{0,2})?/)[0]
            this.setState({ usdtCny:tlnumber });
        }else{
            this.setState({ usdtCny:value });
        }
      }
      //usdt限制輸入
      usdtInputNumberchange = value =>{
        var reg = /^[0-9]+.?[0-9]*$/;
        if (reg.test(value)) {
            let tlnumber = value.toString().match(/^\d+(?:\.\d{0,4})?/)[0]
            this.setState({ usdNmuber:tlnumber });
        }else{
            this.setState({ usdNmuber:value });
        }
      }
      pricecread_buy = url =>{
             let usdtCny = Number(this.state.usdtCny)
             let usdtprice;
             if(this.state.tabsoffer=='buy'){
                  usdtprice = Number(this.state.buyoffer)
             }else{
                  usdtprice = Number(this.state.selloffer)
             }
            if(usdtCny<100){
                return message.error('單筆金額請大於100CNY');
            }
            // console.log(usdtCny,usdtCny/usdtprice)
            const newLoadings = [...this.state.loadings];
            newLoadings[0] = true;
            this.setState({
              loadings: newLoadings,
            });
            let coin_amount = (usdtCny/usdtprice)
                coin_amount = coin_amount.toString().match(/^\d+(?:\.\d{0,4})?/)[0]
            axios.post(`/v1/web/otc/${url}`,{
                "coin_amount":coin_amount,
                "legal_currency_amount":usdtCny,
                "price_timestamp": this.state.timestamp
            }).then(res=>{
                newLoadings[0] = false;
                this.setState({ loadings: newLoadings });
                this.setState({ usdtCny: '' });
                if(res.code==0){
                  
                    // window.open(res.data.pay_url)
                    if(clientInfo.device=='PC'){
                        window.open(res.data.pay_url,'_blank')
                    }else{
                           window.location.href = res.data.pay_url
                    }
                }else{
                    return message.error('交易失敗');
                }
            })
       
      }
      usdtcread_buy = url =>{
        let usdtNumber = Number(this.state.usdNmuber)
       
        let usdtprice;
        if(this.state.tabsoffer=='buy'){
           usdtprice = Number(this.state.buyoffer)
        }else{
           usdtprice = Number(this.state.selloffer)
        }
        if(usdtNumber<20){
            return message.error('最小下單數量為20USDT');
        }
        if(usdtNumber>6500){
            return message.error('最大下單數量為6500USDT');
        }
        let cnyamount = (usdtNumber * usdtprice).toString().match(/^\d+(?:\.\d{0,4})?/)[0]
        const newLoadings = [...this.state.loadings];
        newLoadings[0] = true;
        this.setState({
          loadings: newLoadings,
        });
        axios.post(`/v1/web/otc/${url}`,{
            "coin_amount":usdtNumber,
            "legal_currency_amount":cnyamount,
            "price_timestamp": this.state.timestamp
        }).then(res=>{
            newLoadings[0] = false;
            this.setState({ loadings: newLoadings });
            this.setState({ usdNmuber: '' });
            if(res.code==0){
                // window.open(res.data.pay_url)
                if(clientInfo.device=='PC'){
                    window.open(res.data.pay_url,'_blank')
                }else{
                       window.location.href = res.data.pay_url
                }
            }else{
                return message.error('交易失敗');
            }
        })
        // console.log(usdtNumber,usdtprice,cnyamount)
      }
      submit = index =>{
        if(this.state.tabsoffer=='buy'){
            if(this.state.buyoffer<='0'){
                return
            }
            if(this.state.modevalue=='CNY'){
                this.pricecread_buy('order_create_buy')
            }else{
                this.usdtcread_buy('order_create_buy')
            }
        }else{
            if(this.state.selloffer<='0'){
                return
            }
            if(this.state.modevalue=='CNY'){
                this.pricecread_buy('order_create_sell')
            }else{
                this.usdtcread_buy('order_create_sell')
            }
        }
      }
    render() {
        const { loadings } = this.state;
      return (
       <div className="user-l-p trade-buyusdt">
            <Tabs className="trade-tabs" defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="購買" key="1">
                </TabPane>
                <TabPane  tab="出售" key="2">
                </TabPane>
            </Tabs>
            <Tabs className="trade-tabs " defaultActiveKey="1" >
                <TabPane tab="USDT" key="1">
                </TabPane>
            </Tabs>
            
            <br></br>
           <p className="usdt-pl60 usdt-pl60-pc" >
                <span style={{'marginBottom':'30px','display':'inline-block'}}>
                <b>壹鍵買幣</b> 
                {this.state.modevalue=='CNY'&&<span>單筆限額 100 -- 50000 CNY</span>}
                {this.state.modevalue=='USDT'&&<span>單筆限額 20 -- 6500 USDT</span>}
                { this.state.buyoffer>0&&this.state.selloffer>0&&
                <span style={{'marginLeft':'30px'}}>單價 {this.state.tabsoffer=='buy'?this.state.buyoffer:this.state.selloffer} </span>}
                </span>
               <span className="usdt-fl" >
               <Input.Group compact className="usdtipt" >
                    <Select defaultValue="price" onChange={this.handleChange} className="LoginSelect">
                      <Option value="price" key="1">{this.state.tabsoffer=='buy'?'按金額購買':'按金額出售'}</Option>
                      <Option value="usdtcount" key="2">{this.state.tabsoffer=='buy'?'按數量購買':'按數量出售'}</Option>
                    </Select>
                    {this.state.modevalue=='CNY'&&
                    <InputNumber min={0} max={50000} className="Loginipt" 
                     placeholder={this.state.moveplaceholder}  
                     value={this.state.usdtCny}
                     onChange={this.InputNumberchange}
                     />
                    }
                    {this.state.modevalue=='USDT'&&
                    <InputNumber min={0} max={6500}  className="Loginipt" 
                     placeholder={this.state.moveplaceholder}  
                     value={this.state.usdNmuber}
                     onChange={this.usdtInputNumberchange}/>
                    }
                    <span className="usdt-ipt-tip">{this.state.modevalue}</span>
               </Input.Group>
               <Button  loading={loadings[0]} onClick={()=>{this.submit(0)}} type="primary" shape="round" icon={<ThunderboltOutlined />} size="large">
                        {this.state.tabsoffer=='buy'?'購買USDT':'出售USDT'} 
               </Button>
               </span>
            </p>
            <div className="usdt-pl60 usdt-pl60-h5">
                <p className="usdt-pl60">
                    <b>壹鍵買幣</b>
                    <br></br>
                    {this.state.modevalue=='CNY'&&<span>單筆限額 100 -- 50000 CNY</span>}
                    {this.state.modevalue=='USDT'&&<span>單筆限額 20 -- 6500 USDT</span>}
                    { this.state.buyoffer>0&&this.state.selloffer>0&&
                    <span style={{'marginLeft':'30px'}}>單價 {this.state.tabsoffer=='buy'?this.state.buyoffer:this.state.selloffer} </span>}
                </p>
                <Input.Group compact className="usdtipt" >
                    <Select defaultValue="price" onChange={this.handleChange} className="LoginSelect">
                      <Option value="price" key="1">{this.state.tabsoffer=='buy'?'按金額購買':'按金額出售'}</Option>
                      <Option value="usdtcount" key="2">{this.state.tabsoffer=='buy'?'按數量購買':'按數量出售'}</Option>
                    </Select>
                    {this.state.modevalue=='CNY'&&
                    <InputNumber min={0} max={50000} className="Loginipt" 
                     placeholder={this.state.moveplaceholder}  
                     value={this.state.usdtCny}
                     onChange={this.InputNumberchange}
                     />
                    }
                    {this.state.modevalue=='USDT'&&
                    <InputNumber min={0} max={6500}  className="Loginipt" 
                     placeholder={this.state.moveplaceholder}  
                     value={this.state.usdNmuber}
                     onChange={this.usdtInputNumberchange}/>
                    }
                    <span className="usdt-ipt-tip">{this.state.modevalue}</span>
               </Input.Group>
               <br></br>
               <Button  loading={loadings[0]} onClick={()=>{this.submit(0)}} type="primary" shape="round" icon={<ThunderboltOutlined />} size="large">
                        {this.state.tabsoffer=='buy'?'購買USDT':'出售USDT'} 
               </Button>
            </div>
            <br></br>
            
            <p className="usdt-pl60">
               <b>自選交易</b> <span>更多選擇，自由交易</span>
            </p>
            <br></br>
            <p >暫無數據</p>
           
       </div>
      );
    }
  }

export default Trade;