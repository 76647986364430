import React from 'react';
import ReactDOM from 'react-dom';
import VConsole from 'vconsole/dist/vconsole.min.js' //import vconsole
import './index.css';
import './index.scss';
// import App from './App';
import Router from './route/router';
import Indexs from './page/index';
import * as serviceWorker from './serviceWorker';
// let vConsole = new VConsole() // 初始化/

ReactDOM.render(
    <Router />
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
