import React, { Component } from 'react';
import Icon,{UserOutlined} from '@ant-design/icons';

import { Layout, Menu, Breadcrumb, Button ,Avatar,Popover} from 'antd';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";

import Login from '../login/login';
import Register from '../login/register';

import banner from '../index/banner';
import Mas from '../index/Mas';
import Trader from '../index/Trader';
import Faq from '../index/Faq';
import About from '../index/About';

import Popovercontent from '../Component/Popovercontent';
import FooterS from '../Component/footer';
import axios from '../public/axios'
// import imgss from '../static/logo_pay.png'
import './indexpage.scss'
const { SubMenu } = Menu;
const { Header, Content, Sider ,Footer} = Layout;


function gettoken(){
  let token =   window.localStorage.getItem('otcc-token')
  if(token&&token!=='null'){
    return true
  }else{
    return false
  }
}
function emit(){
  axios.get('/v1/login/exit').then(res=>{
     window.localStorage.setItem('elptoken',null)
     window.localStorage.setItem('userinfo',null)
     window.localStorage.setItem('otcc-token',null)
     window.location.reload() 
  })
}

const title = (
  <div className="curs">
    <br></br>
   {gettoken()&& <p>Hi,{JSON.parse(window.localStorage.getItem('userinfo')).name}</p>}
   {gettoken()&& <p>{JSON.parse(window.localStorage.getItem('userinfo')).mobile}</p> }
  </div>
);
class Laytest extends Component {
  constructor(props) {
    super(props);
    //react定義數據
    this.state = {
    }
  }
  gettoken= () =>{
    let token =   window.localStorage.getItem('otcc-token')
    if(token&&token!=='null'){
      return true
    }else{
      return false
    }
    console.log(token)
    // return token
  }
  onClickredurl(url){
    this.props.history.push(url);
     window.location.reload() 
  }
  
  onClickemit(){
    axios.get('/v1/login/exit').then(res=>{
      window.localStorage.setItem('elptoken',null)
      window.localStorage.setItem('userinfo',null)
      window.localStorage.setItem('otcc-token',null)
      window.location.reload() 
   })
  }
  onClickurl = (url) =>{
    this.props.history.push(url);
    window.location.reload() 
  }
  clicc2c(){
    if(this.gettoken()){
        this.props.history.push('/Trade');
        window.location.reload()  
    }else{
        this.props.history.push('/Login');
        window.location.reload()  
    }
  }
  render() {
    return (
      <Router>
        <Layout>
          <Header className="header" style={{ position: 'fixed', zIndex: 16, width: '100%',paddingRight:'60px' }}>
            <div className="logo logo-header" ><img  src={require('../static/logo_pay.png')} alt="logo" /></div>
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={[this.props.history.location.pathname]}
              style={{ lineHeight: '54px' }}
            >
                      {/* <Menu.Item key="/index1" ><Link to="/index"><img style={{  width: '100px' }} src={require('../static/logo_pay.png')} alt="logo" /></Link></Menu.Item> */}
                    <Menu.Item key="/index" ><Link to="/index">首頁 </Link></Menu.Item>
                    {/* <Menu.Item key="/Mas"><Link to="/Mas">企業方案</Link></Menu.Item>
                    <Menu.Item key="/Trader"><Link to="/Trader">個人入駐</Link></Menu.Item> */}
                    <Menu.Item key="/Faq"><Link to="/Faq">幫助中心</Link></Menu.Item>
                    <Menu.Item key="/About"><Link to="/About">關於我們</Link></Menu.Item>
                    <Menu.Item key="/Trade"><a  onClick={(i)=> this.clicc2c()}>OTC交易</a></Menu.Item>
                    
                    { this.gettoken()&& <Menu.Item className="loginbtn"  key="/wallets">
                      
                        <Popover placement="bottom"
                         content={<Popovercontent 
                          onClick={(i)=> this.onClickredurl('/Trade')}
                          onClick2={(i)=> this.onClickredurl('/wallets')}
                          onClick3={(i)=> this.onClickredurl('/Setup')}
                          onClick4={(i)=> this.onClickemit(i)}
                          />} 
                         title={title} trigger="hover">
                           <Button  onClick={(i)=> {this.onClickredurl('/wallets')}} type="primary" style={{ backgroundColor: '#001529' ,'borderColor':'#e6f7ff'}}>
                           <UserOutlined />
                             <span   style={{ marginLeft: '0px' }}>個人中心</span>
                             </Button>
                        </Popover>
                      
                     </Menu.Item>}
                  { !this.gettoken()&& <Menu.Item className="loginbtn"  key="/register">
                        <Link to="/register">
                          <Button type="primary" >
                          註冊
                          </Button>
                        </Link>
                     </Menu.Item>}
                     { !this.gettoken()&& <Menu.Item className="loginbtn"  key="/Login">
                        <Link to="/Login">
                          登陸
                        </Link>
                    </Menu.Item>}
                   
            </Menu>
           
          </Header>
          <Layout>
          {/* <Sider width={200} style={{ background: '#fff' }}>
              <Menu
                mode="inline"
                
                defaultSelectedKeys={['9']}
                defaultOpenKeys={[this.props.history.location.pathname]}
                style={{ height: '100%', borderRight: 0 }}
                
              >
                <SubMenu
                  key="/index"
                  title={
                    <span>
                      <Icon type="user" />
                      圖表相關
                      </span>
                  }
                >
                  <Menu.Item key="1"> <Link to="/child">統計圖</Link></Menu.Item>
                  <Menu.Item key="2"> <Link to="/child">table統計圖</Link></Menu.Item>
                  <Menu.Item key="3"><Link to="/child">樹形菜單</Link></Menu.Item>

                </SubMenu>
                <SubMenu
                  key="/child"
                  title={
                    <span>
                      <Icon type="laptop" />
                      數據請求
                      </span>
                  }
                >
                  <Menu.Item key="5"> <Link to="/child">表格</Link></Menu.Item>
                  <Menu.Item key="6"> <Link to="/child">樹形控件</Link></Menu.Item>
                  <Menu.Item key="7"><Link to="/tab">Tab</Link></Menu.Item>
                  <Menu.Item key="8"><Link to="/tab">Nav</Link></Menu.Item>
                </SubMenu>
                <SubMenu
                  key="/tab"
                  title={
                    <span>
                      <Icon type="notification" />
                      ant組件
                      </span>
                  }
                >
                  <Menu.Item key="9"><Link to="/tab">輪播圖</Link></Menu.Item>
                  <Menu.Item key="10"><Link to="/tab">卡片效果</Link></Menu.Item>
                  <Menu.Item key="11"><Link to="/tab">add</Link></Menu.Item>
                  <Menu.Item key="12">option12</Menu.Item>
                </SubMenu>
              </Menu>
         </Sider> */}
            <Layout >
              {/* <Breadcrumb style={{ marginTop: '86px' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
                <Breadcrumb.Item>App</Breadcrumb.Item>
              </Breadcrumb> */}
              <Content
              className="site-layout"
                style={{
                  background: '#fff',
                  marginTop:'54px',
                  minHeight: 780,
                }}
              >
                <Route exact path="/index" component={banner} />
                <Route exact path="/Mas" component={Mas} />
                <Route exact path="/Trader" component={Trader} />
                <Route exact path="/Faq" component={Faq} />
                <Route exact path="/About" component={About} />
                <Route exact path="/Login" component={Login} />
                <Route exact path="/register" component={Register} />
                
              </Content>
            </Layout>
          </Layout>
          <Footer style={{ textAlign: 'center' }}><FooterS/></Footer>
        </Layout>

      </Router>
    )
  }
}
export default withRouter(Laytest);