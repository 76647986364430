import React from 'react';
import './agreement.scss'
class Agreement extends React.Component {
    constructor(props){
        super(props)
        this.state={
            value:null
        }
    }
    render() {
      return (
        <div className="agreement-compon">
             <h3>壹、 前言說明</h3>
                        <br></br>
                        <p>
                            1.本協議由交易員和塞舌爾公司名.（以下簡稱“XPay”或“本平臺”）簽訂。本協議有助於交易員了解本平臺為交易員提供的服務內容及交易員的權利和義務，請仔細閱讀。本協議適用於交易員的訪問和使用由本平臺提供的任何服務，包括但不限於本平臺網站www.xpay.pro以及交易員管理中心
                        </p>
                        <br></br>
                        <p>
                            2.為明確交易員與本平臺之間的權責，便於交易員使用交易員服務，交易員通過網絡頁面點擊或其他形式確認本協議的，即視為交易員已充分理解本協議所有條款，並同意與XPay訂立本協議且接受本協議的約束。本協議具有合同效力。
                        </p>
                        <br></br>
                        <h3>二、 特別提示</h3><br></br>
                        <p>
                            1.數字資產本身不由任何金融機構或公司或本平臺發行；數字資產交易存在極高風險，其全天不間斷交易，沒有漲跌限制，價格容易受莊家、全球政府政策的影響而大幅波動
                        </p>
                        <br></br>
                        <p>
                            2. 交易員承諾已了解本平臺僅作為交易員獲取數字貨幣資產信息、匹配交易方、就數字貨幣資產的交易進行協商仲裁及提供開展交易的平臺，本平臺不參與交易員的任何交易，故交易員應自行謹慎判斷確定相關數字資產及/或信息的真實性、合法性和有效性，並自行承擔因此產生的責任與損失。
                        </p>
                        <br></br>
                        <p>
                            3. 禁止使用本平臺從事洗錢、走私、商業賄賂等壹切非法交易活動或違法行為，若發現任何涉嫌非法交易或違法行為，本平臺將采取各種可使用措施，包括但不限於凍結賬戶，通知相關權力機關等，本平臺不承擔由此產生的所有責任並保留向相關人士追究責任的權利。
                        </p>
                        <br></br>
                        <p>
                            4.對於惡意詐騙的交易者，嚴重者本平臺將采取相關司法途徑處理。
                        </p>
                        <br></br>
                        <h3>三、 註冊</h3><br></br>
                        <p>
                            <b>1.註冊資格</b>
                        </p>
                        <br></br>
                        <p>
                            1.交易員確認並承諾：在交易員完成註冊程序或以其他本平臺允許的方式實際使用本網站提供的服務時，交易員應當是具備可適用的法律規定的可簽署本協議及使用本平臺服務應具有的能力的自然人、法人或其他組織
                        </p>
                        <br></br>
                        <p>
                            2.交易員壹旦點擊同意註冊按鈕，即表示交易員自身或交易員的有權代理人已經同意該協議內容並由其代理註冊及使用本平臺服務。
                        </p>
                        <br></br>
                        <p>
                            3.如交易員不具備本協議約定的註冊資格，則本平臺有權拒絕交易員進行註冊，對已註冊的，本平臺有權註銷或永久凍結交易員賬戶交易員的交易員賬號，本平臺保留向交易員或交易員的有權代理人追究責任的權利。同時，本平臺保留其他任何情況下決定是否接受交易員註冊的權利。
                        </p>
                        <br></br>
                        <p>
                            4.本平臺發現賬戶使用者並非賬戶初始註冊人時，有權中止或終止該賬戶的使用。
                        </p>
                        <br></br>
                        <p>
                            5.交易員需具備承擔因個人交易操作失誤、資產凍結或有可能的市場變化風險所導致的壹切資產損失的能力，本平臺對此產生的壹切資產損失概不負責。
                        </p>
                        <br></br>
                        <p>
                            6.交易員已經知曉交易員賬戶的分級及其不同申請資格和交易權限，本平臺擁有隨時對交易員等級變更及不同等級申請內容變更的權利。
                        </p>
                        <br></br>
                        <p>
                            7.交易員已經知曉在本平臺上交易需綁定真實有效的本人實名銀行卡、本人支付寶或微信二維碼進行交易，否則用戶有權拒絕或取消交易員的訂單或對此可能產生的資金凍結導致資產損失均由交易員自行承擔。
                        </p>
                        <br></br>
                        <p>
                            8.交易員已經知曉在通過本平臺進行交易時，不可重復綁定同壹張支付寶或微信收款二維碼，對此可能造成的資產損失和其他壹切後果本平臺概不負責，交易員需自行承擔。
                        </p>
                        <br></br>
                         <p>
                            <b>2.註冊目的</b>
                        </p>
                        <br></br>
                         <p>
                            交易員確認並承諾：交易員進行本平臺註冊並非出於違反法律法規或破壞本平臺數字資產交易秩序的目的。
                        </p>
                        <br></br>
                         <p>
                            <b>3.註冊資料</b>
                        </p>
                        <br></br>
                         <p>
                           1.交易員同意根據本平臺用戶註冊頁面的要求提供合法、真實、準確的姓名、手機號碼、身份證正反面照片、手持證件照和實名認證視頻（按照本平臺要求錄制）等信息。如交易員申請2級及以上等級的交易員，交易員還需提供住址證明（居住證，駕駛證，銀行賬單地址，水電繳費單四選壹）和最近三個月銀行交易流水。
                        </p>
                        <br></br>
                         <p>
                           2.如註冊信息有變動，交易員需要及時更新註冊數據。交易員應對該等信息的真實性、完整性、及時性和準確性負責，並承擔因此產生的任何直接或間接損失及不利後果。
                        </p>
                        <br></br>
                         <p>
                           3.如交易員所在主權國家或地區的法律法規、規則、命令等規範對手機號碼有實名要求，交易員同意提供註冊的手機號碼是經過實名登記的，如交易員不按照規定提供，因此給交易員帶來的任何直接或間接損失及不利後果均應由交易員承擔
                        </p>
                        <br></br>
                        <h3>四、 保證金</h3><br></br>
                        <p>
                            1.交易員註冊及認證完成後，需繳納規定數量的USDT作為入駐XPay交易員的保證金。保證金繳納數量請參照本平臺發布的官方文件，本平臺擁有隨時對保證金繳納數量變更的權利。
                        </p>
                        <br></br>
                        <p>
                            2.交易員繳納的保證金由本平臺統壹妥善保管。合作期間，保證金將作為凍結部分，交易員不可提取且不可用於交易。當交易員發起銷戶申請並通過流程審核後，本平臺將於7個工作日內返還保證金。如交易員在銷戶時仍存在交易糾紛或未處理完成的申訴訂單，本平臺有權不予退還保證金，直至交易糾紛得到解決。
                        </p>
                        <br></br>
                        <p>
                            3.交易員繳納的保證金將用於保障本平臺用戶與交易員之間的交易安全。當發生交易糾紛時，本平臺將會根據實際的交易情況及相關證明文件（包含銀行匯款截圖、平臺交易記錄等）進行交易仲裁。如交易員存在惡意詐騙或無法履行其責任， XPay有權對交易員保證金進行沒收或處置。
                        </p>
                        <br></br>
                        <h3>五、 交易</h3>
                        <br></br>
                        <p>
                            本平臺有權對交易員作出任何交易限制及規則，交易員需服從並執行本平臺發布的所有規範及管理條款，如有違反本平臺制定的規則，本平臺有權隨時關閉交易員接單權限，出現情節嚴重者，本平臺有權進行封號並有權不退還交易員保證金。
                        </p>
                        <br></br>
                        <p>
                            1.交易員如支持支付寶收付方式，應使用規範、可信任的支付寶頭像，且每日自查支付寶賬戶是否出現異常情況並及時更換二維碼或暫停支付寶接單。如超過三位以上用戶就以上情況進行投訴，本平臺有權暫停該交易員接單權限。
                        </p>
                        <br></br>
                        <p>
                            2.交易員應在本平臺發起退款消息時，優先處理退款事宜並按流程規範操作。如交易員超過6小時以上未進行處理，本平臺有權暫停該交易員接單權限。
                        </p>
                        <br></br>
                        <p>
                            3.交易員應在接單後快速準確的處理訂單，如超過3位以上用戶投訴，本平臺有權暫停該交易員接單權限。
                        </p>
                        <br></br>
                        <p>
                            4.交易員應對個人資產交易的所有操作負責，需明確本人壹旦點擊確認收款按鈕即意味同意放行個人資產。如交易員超過三次以上未收到款點擊確認收款按鈕，導致多次尋求本平臺追溯款項，本平臺有權暫停該交易員接單權限。本平臺對壹切無法追回的資產損失概不負責。
                        </p>
                        <br></br>
                        <h3>六、 服務</h3>
                        <br></br>
                        <p>
                           <b>1.服務內容</b>
                        </p>
                        <br></br>
                        <p>
                            1.XPay為數字貨幣擔保交易平臺，本平臺只為交易員通過本平臺進行數字資產擔保交易活動（包括但不限於數字資產交易擔保等服務）提供網絡交易平臺服務，本平臺並不作為買家或賣家參與買賣數字資產行為本身；本平臺不提供任何國家法定貨幣充入和提取的相關服務。
                        </p>
                        <br></br>
                        <p>
                            2.交易員同意接收本平臺發送的與本平臺管理、運營相關的電子郵件，電話，短信等信息。
                        </p>
                        <br></br>
                        <p>
                            3.本平臺有權根據市場情況調整提現、轉賬、交易等手續費費率，有權決定活動推廣期的終止。
                        </p>
                        <br></br>
                        <p>
                            4.交易員可以按照本平臺發布的活動規則參與本平臺組織的網站活動。
                        </p>
                        <br></br>
                        <p>
                            5.本平臺承諾為交易員提供的其他服務。
                        </p>
                        <br></br>
                        <p>
                           <b>2.服務終止</b>
                        </p>
                        <br></br>
                        <p>
                            1.XPay本平臺保留隨時修改或中斷服務而無需另行通知交易員的權利，XPay本平臺有權行使修改或中斷服務的權利，不需對交易員或任何第三方負責。
                        </p>
                        <br></br>
                        <p>
                            2.本協議終止後，交易員無權要求本平臺繼續向交易員提供任何服務或履行任何其他義務。
                        </p>
                        <br></br>
                        <p>
                            3.本協議的終止不影響守約方向違約方要求其他責任的承擔。
                        </p>
                        <br></br>
                        <h3>七、 交易員的權利和義務</h3><br></br>
                        <p>
                           <b>1. 權利</b>
                        </p>
                        <br></br>
                         <p>
                            1.交易員有權在本平臺網站瀏覽數字貨幣資產各項產品的實時行情及交易信息、有權通過本網站提交數字貨幣資產交易指令並完成數字資產交易。
                        </p>
                        <br></br>
                         <p>
                            2. 交易員有權修改在本平臺的賬戶個人中心、安全設置中各項可修改信息。
                        </p>
                        <br></br>
                        <p>
                           <b>2. 義務</b>
                        </p>
                        <br></br>
                         <p>
                            1.交易員應當遵守法律法規、法規及政策要求的規定，保證賬戶中所有數字貨幣資產來源的合法性，不得在本平臺或利用本平臺服務從事非法或其他損害本平臺或第三方權益的活動。
                        </p>
                        <br></br>
                         <p>
                            2.交易員應當遵守本平臺不時發布和更新的本協議以及其他服務條款和交易員規範手冊及規則。
                        </p>
                        <br></br>
                        <p>
                            3.交易員有義務妥善保管XPay賬號、交易員賬號的用戶名和密碼、短信驗證碼、谷歌驗證碼，交易員將對用戶名和密碼、和谷歌密鑰安全負全部責任。因交易員的原因導致信息泄露而造成的任何後果和損失由交易員本人承擔，本平臺不負相關責任。
                        </p>
                        <br></br>
                        <p>
                            4.交易員有義務遵守平臺交易規則，接受並執行平臺所發布的獎懲措施或規範文件。
                        </p>
                        <br></br>
                        <p>
                            5.XPay交易員需遵守按時打款、快速放行、不惡意卡單、不隨意取消訂單等誠信交易的基本原則。優質的交易員應當具備快速打款、快速放行，低申訴率、低取消率，服務態度好、服務意識強等特質。
                        </p>
                        <br></br>
                        <p>
                            6.交易員在本平臺進行數字貨幣資產交易時不得惡意幹擾數字資產交易的正常進行、破壞交易秩序；不得以任何技術手段或其他方式幹擾本平臺的正常運行或幹擾其他用戶對本平臺服務的使用；不得以虛構事實等方式惡意詆毀本平臺的商譽。
                        </p>
                        <br></br>
                        <p>
                            7.如交易員因網上交易與其他用戶產生糾紛的，不得通過司法或行政以外的途徑要求本平臺提供相關數據
                        </p>
                        <br></br>
                        <h3>八、 免責</h3>
                        <br></br>
                        <p>
                           下列情況時本平臺無需承擔任何責任
                        </p>
                        <br></br>
                        <p>
                            1.交易員在進行數字貨幣的存提幣操作時，如因個人收發幣地址錯誤導致的資產損失將由交易員本人承擔，本平臺不負相關責任。
                        </p>
                        <br></br>
                        <p>
                            2.交易員同意交易員對交易員的本平臺的賬號、密碼下發生的所有活動（包括但不限於信息披露、發布信息、網上點擊同意或提交各類規則協議、網上續簽協議或購買服務等）承擔責任。
                        </p>
                        <br></br>
                        <p>
                            3.由於交易員將賬戶密碼告知他人或未保管好自己的密碼或與他人共享賬戶或任何其他非本平臺的過錯，導致交易員的個人資料泄露。
                        </p>
                        <br></br>
                        <p>
                            4.由於與本平臺鏈接的其它網站（如網上銀行等）所造成的銀行賬戶信息泄露及由此而導致的任何法律爭議和後果。
                        </p>
                        <br></br>
                        <p>
                            5.任何因用戶向本平臺提供錯誤、不完整、不實信息等造成不能通過認證或遭受任何其他損失，概與本平臺無關。
                        </p>
                        <br></br>
                        <p>
                            6.所有發給交易員的通告，本平臺都將通過正式的頁面公告、站內消息、電子郵件、客服電話、手機短信或常規的信件送達。任何非經本平臺正規渠道獲得的中獎、優惠、返利返傭等活動或信息，本平臺不承擔法律責任。
                        </p>
                        <br></br>
                        <p>
                            7.如交易員在交易過程中發生糾紛，本平臺有權凍結該筆訂單幣資產且擁有資產解凍權。本平臺不承擔因凍結操作所導致的市場價格波動風險的資產損失。
                        </p>
                        <br></br>
                        <p>
                            8.本平臺對用戶進行的基本資料審查，並不代表用戶100%可信，也不代表本平臺與用戶之間存在任何保證關系。交易員需嚴格按照流程交易，做好對交易方的資料審核，保證自身的交易和資產安全。
                        </p>
                        <br></br>
                         <h3>九、 不可抗力</h3><br></br>
                        <p>
                           1.在使用本服務的過程中，可能會遇到不可抗力等風險因素，使本服務發生中斷。不可抗力是指不能預見、不能克服、不能避免且對壹方或雙方造成重大影響的客觀事件，包括但不限於洪水、地震、瘟疫和風暴等自然災害以及戰爭、動亂、政府行為等社會事件。
                        </p>
                        <br></br>
                        <p>
                           2.本平臺對任何由於第三方原因及其他非本平臺過錯而造成的服務中斷或受阻不承擔責任，本平臺將努力在第壹時間與相關單位配合及時進行修復。
                        </p>
                        <br></br>
                        <p>
                           3.受到計算機病毒、木馬或其他惡意程序、黑客攻擊的破壞。
                        </p>
                        <br></br>
                        <p>
                           4.電腦軟件、系統、硬件和通信線路出現故障。
                        </p>
                        <br></br>
                        <p>
                           5. 銀行系統或銀行網絡出現故障。
                        </p>
                        <br></br>
                        <p>
                           6.其他本公司無法控制或合理預見的情形。
                        </p>
                        <br></br>
                        <p>
                           7.交易員在使用本服務的過程中，本公司不對下列風險擔責
                        </p>
                        <br></br>
                        <p>
                           (1).對於交易員在接受本平臺的服務過程中可能遇到的錯誤、侮辱、誹謗、不作為、淫穢、色情或褻瀆事件，本平臺不承擔法律責任。
                        </p>
                        <br></br>
                        <p>
                           (2).遭受他人誤導、欺騙或其他原因而導致的心理、生理上的傷害以及經濟上的損失。
                        </p>
                        <br></br>
                        <p>
                           (3).其他因網絡信息或妳自身行為引起的風險。
                        </p>
                        <br></br>
                         <h3>十、 數據安全與隱私權保護</h3><br></br>
                        <p>
                          <b>1.本協議所稱之本平臺交易員信息是指符合法律、法規及相關規定，並符合下述範圍的信息：</b> 
                        </p>
                        <br></br>
                        <p>
                           1.交易員註冊本平臺時，向本平臺提供的個人信息。
                        </p>
                        <br></br>
                        <p>
                           2.交易員在使用本平臺服務、參加本平臺活動或訪問網站網頁時，本平臺自動接收並記錄的交易員的瀏覽器端數據，包括但不限於IP地址、網站Cookie中的數據及交易員要求取用的網頁記錄。
                        </p>
                        <br></br>
                        <p>
                           3.本平臺從商業夥伴處合法獲取的交易員的個人信息。
                        </p>
                        <br></br>
                        <p>
                           4.其它本平臺通過合法途徑獲取的交易員的個人信息
                        </p>
                        <br></br>
                        <p>
                          <b>2.本平臺承諾：未經法定原因或交易員的事先許可，本平臺不會向任何第三方透露交易員的密碼、姓名、手機號碼、證件號碼等非公開信息</b> 
                        </p>
                        <br></br>
                        <p>
                           <b>3.在下述法定情況下，交易員的個人信息將會被部分或全部披露：</b>
                        </p>
                        <br></br>
                        <p>
                           1.經交易員的同意向交易員本人或其他第三方披露。
                        </p>
                        <br></br>
                        <p>
                           2.根據法律、法規等相關規定，或行政機構要求，向行政、司法機構或其他法律規定的第三方披露。
                        </p>
                        <br></br>
                        <p>
                           3.其他本平臺根據法律、法規等相關規定進行的披露。
                        </p>
                        <br></br>
                         <h3>十壹、 本協議的修訂</h3><br></br>
                        <p>
                          為了提升服務質量，本平臺將根據法律、法規及其他規範性文件及業務需要，可能增加、變更服務內容或服務方式。交易員理解並同意XPay可能對本協議內容進行變更，且無需單獨通知交易員。交易員必須定期查看本條款，以查看這些變更。若交易員不接受本協議的變更，交易員有權自主決定選擇停止使用本服務。若交易員在協議變更後繼續使用本服務，表示交易員已經充分閱讀、理解並接受變更修改後的協議內容，也將遵循變更修改後的協議內容使用本服務。
                        </p>
                        <br></br>
                        <h3>十二、 法律適用與管轄權</h3><br></br>
                        <p>
                         本協議全部內容均為根據塞舌爾共和國法律訂立的合同，其成立、解釋、內容及執行均適用塞舌爾共和國相關法律規定；任何因或與本協議約定的服務有關而產生的索賠或訴訟，都應依照塞舌爾共和國的法律進行管轄並加以解釋和執行。為避免疑義，這壹條款明確適用於任何針對本平臺的侵權索賠。任何針對本平臺或者是和本平臺有關的索賠或訴訟的管轄法院或訴訟地均在塞舌爾共和國。交易員無條件地獲得在塞舌爾共和國法院進行訴訟和上訴的排他性的管轄權。交易員也無條件地同意與本協議款有關的爭議或問題或產生的任何索賠請求和訴訟的發生地或法院均排他性得在塞舌爾共和國，如有本平臺其他業務對管轄有專門約定從其約定。不方便法院的原則不適用於根據本服務條款的選擇的法院。
                        </p>
                        <br></br>
 
        </div>
      );
    }
  }

export default Agreement;