import React, { Component } from 'react';
import Icon,{UserOutlined,AppstoreOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    PieChartOutlined,
    FallOutlined,
    ThunderboltOutlined,
    SafetyOutlined,
    IdcardOutlined,
    FundProjectionScreenOutlined,
    UsergroupAddOutlined,
    TeamOutlined,
    CreditCardOutlined,
    DesktopOutlined,
    ContainerOutlined,
    MailOutlined} from '@ant-design/icons';

import { Layout, Menu, Breadcrumb, Button ,Avatar,Popover} from 'antd';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";

import Trade from './trade';
import wallets from './wallets';
import Orderhistory from './Orderhistory';
import OrderhistoryDetail from './OrderhistoryDetail';
import Listofassets from './Listofassets';
import Deposit from './Deposit';
import Withdraw from './Withdraw';
import Security from './security';
import Uppassword from './uppassword';
import set_google from './set_google';

import kycCertification from './kycCertification';
import Collectionlist from './Collectionlist';
import CollectionlistSet from './CollectionlistSet';

import Popovercontent from '../Component/Popovercontent';

import FooterS from '../Component/footer';
import axios from '../public/axios'
// import imgss from '../static/logo_pay.png'
import './index.scss'
const { SubMenu } = Menu;
const { Header, Content, Sider ,Footer} = Layout;


function gettoken(){
  let token =   window.localStorage.getItem('otcc-token')
  if(token&&token!=='null'){
    return true
  }else{
    return false
  }
}

const title = (
  <div className="curs">
    <br></br>
   {gettoken()&& <p>Hi,{JSON.parse(window.localStorage.getItem('userinfo')).name}</p>}
   {gettoken()&& <p>{JSON.parse(window.localStorage.getItem('userinfo')).mobile}</p> }
  </div>
);
class Laytest extends Component {
  constructor(props) {
    super(props);
    //react定義數據
    this.state = {
        collapsed: false,
    }
  }
  //判斷是否登陸
  gettoken= () =>{
    let token =   window.localStorage.getItem('otcc-token')
    if(token&&token!=='null'){
      return true
    }else{
      return false
    }
  }
  //判斷是否登陸
  componentDidMount(){
    if(this.gettoken()){
        axios.get('/v1/web/v2/cert_kyc_status').then(res=>{
          if(res.code==0){
              window.localStorage.setItem('Certification','已認證')
          }else{
                if(res.code==6354789){
                    window.localStorage.setItem('Certification','正在審核中..')
                }else{
                     window.localStorage.setItem('Certification','未認證')
                }
          }
        })
    }else{
      this.props.history.push('/login');
      window.location.reload() 
    }
  }
  //登陸後的菜單
  onClickredurl(url){
    this.props.history.push(url);
     window.location.reload() 
  }
  //退出
  onClickemit(){
    axios.get('/v1/login/exit').then(res=>{
      window.localStorage.setItem('elptoken',null)
      window.localStorage.setItem('userinfo',null)
      window.localStorage.setItem('otcc-token',null)
      this.props.history.push('/login');
      window.location.reload() 
   })
  }
  //頭部跳轉
  onClickurl(url){
    this.props.history.push(url);
    // window.location.reload() 
  }
  //法幣跳轉
  onClickurlf(url){
    this.props.history.push(url);
    window.location.reload() 
  }
  isurl(){
    // console.log(this.props.history.location.pathname)
    if(this.props.history.location.pathname=='/wallets'){
      return this.props.history.location.pathname
    }
    if(this.props.history.location.pathname=='/Orderhistory'){
      return this.props.history.location.pathname
    }
    if(this.props.history.location.pathname=='/Listofassets'){
      return this.props.history.location.pathname
    }
    if(this.props.history.location.pathname=='/Deposit'){
      return this.props.history.location.pathname
    }
    if(this.props.history.location.pathname=='/Withdraw'){
      return this.props.history.location.pathname
    }
    
  }
  isurlTWO(){
    if(this.props.history.location.pathname=='/security'){
      return this.props.history.location.pathname
    }
    if(this.props.history.location.pathname=='/uppassword'){
      return this.props.history.location.pathname
    }
    if(this.props.history.location.pathname=='/set_google'){
      return this.props.history.location.pathname
    }
    if(this.props.history.location.pathname=='/kycCertification'){
      return this.props.history.location.pathname
    }
  }
  isurlThree(){
    if(this.props.history.location.pathname=='/Collectionlist'){
      return this.props.history.location.pathname
    }
    if(this.props.history.location.pathname=='/CollectionlistSet'){
      return this.props.history.location.pathname
    }
  }
  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  clicc2c(){
    if(this.gettoken()){
        this.props.history.push('/Trade');
        window.location.reload()  
    }else{
        this.props.history.push('/Login');
        window.location.reload()  
    }
  }
  render() {
    return (
      <Router>
        <Layout>
          <Header className="header" style={{ position: 'fixed', zIndex: 16, width: '100%' ,paddingRight:'60px' }}>
            <div className="logo logo-header" ><img  src={require('../static/logo_pay.png')} alt="logo" /></div>
            <Menu
              theme="dark"
              mode="horizontal"
              defaultSelectedKeys={[this.props.history.location.pathname]}
             
              style={{ lineHeight: '54px' }}
              
            >
                     {/* <Menu.Item key="/index1" ><Link to="/index"><img style={{  width: '100px' }} src={require('../static/logo_pay.png')} alt="logo" /></Link></Menu.Item> */}
                    <Menu.Item key="/index" onClick={ () =>this.onClickurl('/index')}>首頁 </Menu.Item>
                    <Menu.Item key="/Mas"  onClick={ () =>this.onClickurl('/Mas')}>企業方案</Menu.Item>
                    <Menu.Item key="/Trader"  onClick={ () =>this.onClickurl('/Trader')}>個人入駐</Menu.Item>
                    <Menu.Item key="/Faq"  onClick={ () =>this.onClickurl('/Faq')}>幫助中心</Menu.Item>
                    <Menu.Item key="/About"  onClick={ () =>this.onClickurl('/About')}>關於我們</Menu.Item>
                    <Menu.Item key="/Trade"><a  onClick={(i)=> this.clicc2c()}>OTC交易</a></Menu.Item>
                    { this.gettoken()&& <Menu.Item className="loginbtn"  key="/wallets">
                        <Popover placement="bottom"
                            content={<Popovercontent 
                             onClick={(i)=> this.onClickredurl('/Trade')}
                             onClick2={(i)=> this.onClickredurl('/wallets')}
                             onClick3={(i)=> this.onClickredurl('/Setup')}
                             onClick4={(i)=> this.onClickemit(i)}
                             />} 

                         title={title} trigger="hover">
                           <Button type="primary"  style={{ backgroundColor: '#001529' ,'borderColor':'#e6f7ff'}} >
                           <UserOutlined /> 
                             <span className="ba-w" style={{ marginLeft: '0px',color: 'white'  }}>個人中心</span>
                             </Button>
                        </Popover>
                      
                     </Menu.Item>}
                  { !this.gettoken()&& <Menu.Item className="loginbtn"  key="/register">
                        <Link to="/register">
                          <Button type="primary" >
                          註冊
                          </Button>
                        </Link>
                     </Menu.Item>}
                     { !this.gettoken()&& <Menu.Item className="loginbtn"  key="/Login">
                        <Link to="/Login">
                          登陸
                        </Link>
                    </Menu.Item>}
            </Menu>
          </Header>
          <Layout>
       <div className="user-index-side" >
       <div className="user-collapsed">
        <Button type="primary" onClick={this.toggleCollapsed} style={{ marginBottom: 16 }}>
          {React.createElement(this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
        </Button>
        </div>
        <Menu
        className="user-side-Menu"
          defaultSelectedKeys={[this.props.history.location.pathname]}
          defaultOpenKeys={[this.props.history.location.pathname]}
          style={{ height: '100%', background: '#fff',color:'black',fontSize: '14px'}}
          mode="inline"
          theme="dark"
          inlineCollapsed={this.state.collapsed}
        >
          <Menu.Item onClick={ () =>this.onClickurlf('/Trade')} key="/Trade" className={this.state.collapsed?"usersetli":'usersetli-85'}     icon={<ThunderboltOutlined />}>
              法幣交易
          </Menu.Item>
          
          <SubMenu  className="userset-sbli" style={{ paddingLeft: this.state.collapsed ?'':'40px'}}  key={this.isurl()} icon={<CreditCardOutlined />} 
          title="我的錢包">
                    <Menu.Item key="/wallets"> <Link to="/wallets">個人資產</Link></Menu.Item>
                    <Menu.Item key="/Orderhistory"> <Link to="/Orderhistory">訂單歷史</Link></Menu.Item>
                  <Menu.Item key="/Listofassets"> <Link to="/Listofassets">資金明細</Link></Menu.Item>
                  <Menu.Item key="/Deposit"> <Link to="/Deposit">沖幣</Link></Menu.Item>
                  <Menu.Item key="/Withdraw"><Link to="/Withdraw">提幣</Link></Menu.Item>
          </SubMenu>
          <SubMenu  className="userset-sbli" style={{ paddingLeft: this.state.collapsed ?'':'40px'}} key={this.isurlTWO()} icon={<IdcardOutlined />} title="賬戶設置">
                    <Menu.Item key="/kycCertification" ><Link to="/kycCertification">實名認證</Link></Menu.Item>
                  <Menu.Item key="/security"> <Link to="/security">安全設置</Link></Menu.Item>
                  {/* <Menu.Item key="8"> <Link to="/uppassword">修改密碼</Link></Menu.Item>
                  <Menu.Item key="9"><Link to="/set_google">谷歌驗證碼綁定</Link></Menu.Item> */}
          </SubMenu>
          <SubMenu  className="userset-sbli" style={{ paddingLeft: this.state.collapsed ?'':'40px'}}  key={this.isurlThree()} icon={<UsergroupAddOutlined />} title="收款賬戶">
                 <Menu.Item key="/Collectionlist"><Link to="/Collectionlist">收款列表</Link></Menu.Item>
                  {/* <Menu.Item key="/CollectionlistSet"><Link to="/CollectionlistSet">收款設置</Link></Menu.Item> */}
          </SubMenu>
        </Menu>
      </div>
      <Sider width={242} style={{ background: '#fff',paddingTop: '86px','display':'none' }}  ></Sider>
            <Layout >
              <Content
              className="site-layout"
                style={{
                  background: '#f4f8ff',
                  marginTop:'54px',
                  minHeight: 780,
                }}
              >
                 <Route exact path="/Trade" component={Trade} />
                 <Route exact path="/wallets" component={wallets} />
                 <Route exact path="/Orderhistory" component={Orderhistory} />
                 <Route exact path="/OrderhistoryDetail" component={OrderhistoryDetail} />
                 <Route exact path="/Listofassets" component={Listofassets} />
                 
                 <Route exact path="/Deposit" component={Deposit} />
                 <Route exact path="/Withdraw" component={Withdraw} />
                 <Route exact path="/Security" component={Security} />
                 <Route exact path="/uppassword" component={Uppassword} />
                 
                 <Route exact path="/set_google" component={set_google} />
                 <Route exact path="/kycCertification" component={kycCertification} />
                 <Route exact path="/Collectionlist" component={Collectionlist} />
                 {/* <Route exact path="/CollectionlistSet" component={CollectionlistSet} /> */}
                 
              </Content>
            </Layout>
          </Layout>
          <Footer style={{ textAlign: 'center' }}><FooterS/></Footer>
        </Layout>

      </Router>
    )
  }
}
export default withRouter(Laytest);