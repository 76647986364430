import React from 'react';
import { Card } from 'antd';
class Uppassword extends React.Component {
    constructor(props){
        super(props)
        this.state={
            value:null
        }
    }
    render() {
      return (
        <div className="user-l-p">
              password
        </div>
      );
    }
  }

export default Uppassword;