import React, { Component } from 'react';
import { Carousel,Button ,Row, Col,Card,Input,Divider,Steps,message} from 'antd';
import './Consultation.scss'
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined ,MessageOutlined,WhatsAppOutlined } from '@ant-design/icons';
import axios from '../public/axios'

const { Meta } = Card;
const { Search } = Input;
const { Step } = Steps;
 
class Consultation extends React.Component {
    constructor(props){
        super(props)
        this.state={
            loading:false,
            name:'',
            code:''
        }
    }
    conion(value){
      if(this.state.name==''){
        return message.error('请输入您的姓名');
      }
      if(value==''){
        return message.error('请输入您的联系方式');
      }
      let form = {
        type:'WEB_MAIN',
        name: '',
        company: '',
        phone: '',
        email: '',
        chat_type: '微信',
        chat_account: '',
        web: '',
        address: '',
        business: '',
        // scale: '',
        remark: ''
      }
      var email = value
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if(reg.test(email)){
        form.email = email
      }else{
        form.phone = email
      }
      form.name = this.state.name
      
      this.setState({ loading:true});
      axios.post('/v1/web/reginfo',form).then(res=>{
        this.setState({ loading:false});
          if(res.code==0){
            this.setState({ name: '' });
            this.setState({ code: '' });
             message.success('您的咨询已被记录,请等待我们与您联系');
          }else{
            message.error(res.msg);
          }
      })
     
      // console.log(form)
    }
    render() {
      return (
        <div> 
            <div className="Consultation  bgf4 pt-80" >
                        <h4>合作咨詢</h4>  
                        <Steps className="Consultation-Steps">
                          <Step status="finish" title="接入溝通" icon={<WhatsAppOutlined />} />
                          <Step status="finish" title="接入開發" icon={<SolutionOutlined />} />
                          <Step status="finish" title="聯調測試" icon={<LoadingOutlined />} />
                          <Step status="finish" title="正式上線" icon={<SmileOutlined />} />
                        </Steps>
                        {/* <Steps current={4} className="Consultation-Steps">
                          <Step title="接入溝通"  />
                          <Step title="接入開發"  />
                          <Step title="聯調測試"  />
                          <Step title="正式上線"  />
                        </Steps> */}
                        {/* <p className="Consultation-liuc">
                          
                        <span>1.接入溝通</span>
                        --
                        <span> 2.接入開發</span>
                        --
                        <span>3.聯調測試</span>
                        --
                        <span>4.正式上線</span>
                        </p> */}
                        <br></br>
                        <br></br>
                        <p className="Consultation-p">
                        <Input placeholder="姓名" 
                        value={this.state.name}
                        onChange={(e)=> this.setState({ name: e.target.value })}
                        className="Consultation-name" />
                        <Search
                        className="Consultation-Search"
                        placeholder="郵箱或手機"
                        value={this.state.code}
                        onChange={(e)=> this.setState({ code: e.target.value })}
                        enterButton="提交"
                        size="large"
                        onSearch={value => this.conion(value)}
                        loading={this.state.loading}
                        />
                        </p>
                 </div>
        </div>
          );
        }
      }
    
export default Consultation;