import React from 'react';
import { Card } from 'antd';
class CollectionlistSet extends React.Component {
    constructor(props){
        super(props)
        this.state={
            value:null
        }
    }
    componentDidMount(){
      document.title="收款设置"
  }
    render() {
      return (
        <div className="user-l-p">
              收款列表设置
        </div>
      );
    }
  }

export default CollectionlistSet;