import React from 'react';
import { Table, Tag, Space } from 'antd';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";
import axios from '../public/axios'
import './wallets.scss'
const columns = [
    {
      title: '幣種',
      dataIndex: 'name',
      key: 'name',
      render: text => <a>{text}</a>,
    },
    {
      title: '可用金額',
      dataIndex: 'available',
      key: 'available',
    },
    {
      title: '凍結金額',
      dataIndex: 'freeze_order_value',
      key: 'freeze_order_value',
    },
    {
      title: '總計',
      key: 'tags',
      dataIndex: 'tags',
      render: tags => (
        <>
          {tags.map(tag => {
            let color = tag.length > 5 ? 'geekblue' : 'green';
            if (tag === 'loser') {
              color = 'volcano';
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a href="/Deposit">充值</a>
         <a href="/Withdraw">提現</a>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: '1',
      name: 'USDT',
      age: '99,999,955,251.7360 ',
      address: '99,999,955,251.7360',
      tags: ['99,999,955,251.7360'],
    },
  ];  
class Wallets extends React.Component {
    constructor(props){
        super(props)
        this.state={
            data:[],
            uid:'',
            sum_amount:''
        }
    }
    componentDidMount(){
        document.title="個人資產"
        axios.get('/v1/account/amount?symbol=USDT').then(res=>{
           if(res.code==0){
            //    this.data = res.data
            let data = [{
                'key': 1,
                'name': 'USDT',
                'available':res.data.available.toString().match(/^\d+(?:\.\d{0,4})?/)[0],
                'freeze_order_value':res.data.freeze_order_value.toString().match(/^\d+(?:\.\d{0,4})?/)[0],
                'tags':[res.data.sum_amount.toString().match(/^\d+(?:\.\d{0,4})?/)[0]],
                'uid':res.data._uid,
            }]
            this.setState({ data: data});
            this.setState({ sum_amount: res.data.sum_amount.toString().match(/^\d+(?:\.\d{0,4})?/)[0]});
            this.setState({ uid: res.data._uid});
            //  console.log(res.data)
           }
       })
    }
    render() {
      return (
        <div className="user-l-p wallets-usdt">
            <p className="usdt-pl60"><b>當前資產總估 </b></p>
            <p className="usdt-pl60"><b style={{'fontWeight':'400'}}>總額：</b>
              <span className="fz18">{this.state.sum_amount} (USDT)</span></p>
            <p className="usdt-pl60 fz18">我的UID：{this.state.uid}</p>
            <p className="usdt-pl60 fz18">各幣種資金</p>
            <Table  pagination={{ simple: true ,hideOnSinglePage:true}} style={{'overflowX':'scroll','background':'white'}}  columns={columns} dataSource={this.state.data} ></Table>
        </div>
      );
    }
  }

export default Wallets;