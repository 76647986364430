
import React, { Component } from 'react';
import { List, Avatar ,Row, Col,Tabs,message} from 'antd';
import copy from 'copy-to-clipboard';
import axios from '../public/axios'
import './Deposit.scss'
const { TabPane } = Tabs;
class Deposit extends React.Component {
    constructor(props){
        super(props)
        this.state={
            omniaddress:'',
            omniqrcode:'',
            ercaddress:'',
            ercqrcode:'',
            trcaddress:'',
            trcqrcode:'',
        }
    }
    componentDidMount(){
        document.title="沖幣"
        //omni
        axios.get('/v1/wallet/usdt/get_traders_init_address').then(res=>{
            if(res.code==0){
                this.setState({ omniaddress: res.data.address});
                this.setState({ omniqrcode: res.data.qrcode});
            }
        })
        //erc
        axios.get('/v1/wallet/usdt/get_address?symbol=ETH_USDT').then(res=>{
            if(res.code==0){
                this.setState({ ercaddress: res.data.address});
                this.setState({ ercqrcode: res.data.qrcode});
            }
        })
        //trc
        axios.get('/v1/wallet/usdt/get_address?symbol=TRX').then(res=>{
            if(res.code==0){
                this.setState({ trcaddress: res.data.address});
                this.setState({ trcqrcode: res.data.qrcode});
               }
           })
    }
    copy= text =>{
       copy(text)   
       message.success('復制成功');   
    }
    render() {
      return (
        <div className="user-l-p Deposit-usdt">
            <Row>
                <Col  md={24} lg={12} >
                <p className="usdt-pl60"><b>沖幣  </b></p>
                 <Tabs   type="card" style={{'paddingRight':'30px'}}>
                         <TabPane tab="ERC20" key="1" >
                            <p className="fz16">存幣地址：</p>
                            <div style={{'overflow':'scroll'}} className="fz-16" >{this.state.ercaddress} 
                             <span onClick={()=>{this.copy(this.state.ercaddress)}} className="cr06b curs"> 復制</span>
                            </div>
                            <br></br>
                            <p  >
                                <img src={`data:image/png;base64,${this.state.ercqrcode}`} />
                             </p>
                         </TabPane>
                        <TabPane tab="TRC20" key="2">
                            <p className="fz16">存幣地址：</p>
                            <div style={{'overflow':'scroll'}} className="fz-16" >{this.state.trcaddress} 
                            <span onClick={()=>{this.copy(this.state.trcaddress)}} className="cr06b curs"> 復制</span>
                            </div>
                            <br></br>
                            <p  >
                                <img src={`data:image/png;base64,${this.state.trcqrcode}`}/>
                             </p>
                        </TabPane>
                        <TabPane tab="OMNI" key="3">
                        <p className="fz16">存幣地址：</p>
                            <div style={{'overflow':'scroll'}} className="fz-16" >{this.state.omniaddress} 
                            <span onClick={()=>{this.copy(this.state.omniaddress)}} className="cr06b curs"> 復制</span>
                            </div>
                            <br></br>
                            <p  >
                                <img src={`data:image/png;base64,${this.state.omniqrcode}`}/>
                             </p>
                        </TabPane>
                       
                    </Tabs>
                </Col>
                <Col  md={24} lg={12} >
                <p className="usdt-pl60"><b>提示：  </b></p>
                <List itemLayout="horizontal"  type="card" style={{'paddingRight':'30px'}}>
                     <List.Item>
                               <List.Item.Meta title="1、最小存幣金額為10.00USDT"  />
                     </List.Item>
                     <List.Item>
                               <List.Item.Meta title="2、預計到賬時間為10-60分鐘，如需快速到賬，您可向USDT網絡支付適當礦工費"  />
                     </List.Item>
                     <List.Item>
                               <List.Item.Meta title="3、您的USDT地址不會無故更改，可重復存幣"  />
                     </List.Item>
                     <List.Item>
                               <List.Item.Meta title="4、請務必確認電腦及瀏覽器安全，防止信息被篡改或泄露"  />
                     </List.Item>
                     <List.Item>
                               <List.Item.Meta title="如有疑問，請 聯系客服"  />
                     </List.Item>
                </List>
                </Col>
            </Row>
        </div>
      );
    }
  }

export default Deposit;