import React from 'react';

function Square(props) {
    return (
      <button className="square" onClick={props.onClick}>
        {props.value}
      </button>
    );
  }
// class Square extends React.Component {
//     constructor(props){
//         super(props)
//         this.state={
//             value:null
//         }
//     }
//     render() {
//       return (
//         <button className="square" onClick={()=>{
//             this.props.onClick()
//             // this.setState({
//             //     value:'x'
//             // })
//         }}>
//             {this.props.value}
//           {/* TODO */}
//         </button>
//       );
//     }
//   }

  export default Square;