import React from 'react';
import './Mas.scss'
import Consultation from '../Component/Consultation'

import { Carousel,Button ,Row, Col,Card,Input,Divider,Tabs} from 'antd';
import { MoneyCollectOutlined, AndroidOutlined,SmileOutlined,PayCircleOutlined,TransactionOutlined,RotateRightOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
class Mas extends React.Component {
    constructor(props){
        super(props)
        this.state={
            value:null
        }
    }
    componentDidMount(){
        document.title="企業方案"
    }
    clickapi(){
        window.open('https://apidocs.otcc.io','_blank')
    }
    clickmerchant(){
        window.open('https://merchant.otcc.io','_blank')
    }
    render() {
      return (
        <div className="Mas-page">
            <Carousel  effect="fade" className="Mas-banner">
                    <div className="banner-one">
                        <h3 className="mas-banner-h3" >壹次接入，長期受益</h3>
                        {/* <h4>安全 &nbsp; ·  &nbsp;穩定&nbsp;  ·  &nbsp;可信賴</h4> */}
                        <h4>安全 &nbsp; ·  &nbsp;穩定&nbsp;  ·  &nbsp;可信賴</h4>
                        <p> 
                        <Button type="primary" onClick={()=>{this.clickapi()}} shape="round" size={'large'}>獲取api文檔</Button>
                        <Button type="primary" onClick={()=>{this.clickmerchant()}}  shape="round" size={'large'}>登陸商戶中心</Button></p>
                    </div>
             </Carousel>
             <div className="Different common-connect " >
                        <h4>XPay 的優勢</h4>                        
                        <div className="site-card-wrapper row-connect">
                            <Row  style={{justifyContent:'center'}}>
                            <Col  md={24} lg={8} >
                                <Card 
                                 
                                 className="card-div"
                                 bordered={false}>
                                    <p> <img src={require('../static/jiami1.png')}/></p>
                                    <br></br>
                                    <br></br>
                                    <br></br>
                                    <h3 style={{fontSize:'20px' }}>擔保交易，安全可靠</h3>
                                </Card>
                            </Col>
                            <Col md={24} lg={8}>
                                <Card 
                                 className="card-div"
                                 bordered={false}>
                                     <p> <img src={require('../static/wending.png')}/></p>
                                     <br></br>
                                     <br></br>
                                     <br></br>
                                     <h3 style={{fontSize: '20px' }}>聚合資源，服務穩定</h3>
                                </Card>
                            </Col>
                            <Col md={24} lg={8}>
                                <Card 
                                 className="card-div"
                                bordered={false}>
                                     <p> <img src={require('../static/kuaisu.png')}/></p>
                                     <br></br>
                                     <br></br>
                                     <br></br>
                                     <h3 style={{fontSize: '20px' }}>全程支持，接入簡單</h3>
                                </Card>
                            </Col>
                            </Row>
                        </div>
              </div>
              
              <div className="Different common-connect bgf4 SceneDemonstration" >
                        <h4>XPay業務場景演示</h4>                        
                        <Tabs defaultActiveKey="1">
                            <TabPane
                            tab={
                                <span>
                                <MoneyCollectOutlined />
                                客戶買入流程
                                </span>
                            }
                            key="1"
                            >
                           <img  src={require('../static/buy1.png')} alt="logo" />
                            </TabPane>
                            <TabPane
                            tab={
                                <span>
                                <TransactionOutlined />
                                客戶賣出流程
                                </span>
                            }
                            key="2"
                            >
                             <img  src={require('../static/sell1.png')} alt="logo" />
                            </TabPane>
                        </Tabs>  
              </div>
            <Consultation/>
        </div>
      );
    }
  }

export default Mas;