import React from 'react';
import { List, Avatar ,Row, Col,Tabs} from 'antd';
import './OrderhistoryDetail.scss'
const { TabPane } = Tabs;

class OrderhistoryDetail extends React.Component {
    constructor(props){
        super(props)
        this.state={
            data:{}
        }
    }
    componentDidMount(){
        document.title="訂單歷史"
        let data =    window.localStorage.getItem('orderdetail')
        this.setState({ data: JSON.parse(data)});
        console.log(JSON.parse(data))
    }
    render() {
      return (
        <div className="user-l-p Ordhory-Detail">
              <Row>
                <Col  md={24} lg={12} >
                <p className="usdt-pl60"><b>訂單詳情 </b></p>
                    <List
                            itemLayout="horizontal"
                            style={{'paddingRight':'30px'}}
                    >
                         <List.Item>
                                <List.Item.Meta title="訂單編號：" description={this.state.data.order_no} />
                        </List.Item>
                        <List.Item>
                                <List.Item.Meta title="姓名：" description={this.state.data.name} />
                        </List.Item>
                        <List.Item>
                            {this.state.data.side=='BUY'&&  <List.Item.Meta className="gr" title="訂單類型：" description={'買入'} />}  
                            {this.state.data.side=='SELL'&& <List.Item.Meta className="red" title="訂單類型：" description={'賣出'} />}  
                        </List.Item>
                        <List.Item>
                                <List.Item.Meta title="訂單單價：" description={parseFloat(this.state.data.coin_price)} />
                        </List.Item>
                        <List.Item>
                                <List.Item.Meta title="訂單數量：" description={parseFloat(this.state.data.amount)} />
                        </List.Item>
                        <List.Item>
                                <List.Item.Meta title="訂單金額：" description={parseFloat(this.state.data.legal_amount)} />
                        </List.Item>
                        <List.Item>
                               <List.Item.Meta title="訂單狀態：" description={this.state.data.status} />
                        </List.Item>
                   </List>
                 </Col>
                <Col  md={24} lg={12} >
                <p className="usdt-pl60"><b>支付方式  </b></p>
                    <Tabs  type="card" style={{'paddingRight':'30px'}}>
                        <TabPane tab="支付寶" key="1">
                        
                      <List
                           itemLayout="horizontal"
                          
                         >
                        <List.Item>
                               <List.Item.Meta title="支付寶姓名：" description={this.state.data.alipay_account_name} />
                       </List.Item>
                       <List.Item>
                               <List.Item.Meta title="支付寶賬號：" description={this.state.data.alipay_account_no} />
                       </List.Item>
                       </List>
                        </TabPane>
                        <TabPane tab="銀行卡" key="2">
                        <List
                           itemLayout="horizontal"
                          
                         >
                        <List.Item>
                               <List.Item.Meta title="銀行卡姓名：" description={this.state.data.bank_account_name} />
                       </List.Item>
                       <List.Item>
                               <List.Item.Meta title="銀行名稱：" description={this.state.data.bank_name} />
                       </List.Item>
                       <List.Item>
                               <List.Item.Meta title="銀行地址：" description={this.state.data.bank_branch_name} />
                       </List.Item>
                       <List.Item>
                               <List.Item.Meta title="銀行卡號：" description={this.state.data.bank_account_no} />
                       </List.Item>
                       </List>
                        </TabPane>
                    </Tabs>
                </Col>
                </Row>
        </div>
      );
    }
  }

export default OrderhistoryDetail;