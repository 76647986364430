import React from 'react';
import './Trader.scss'
import ConsultationTrader from '../Component/ConsultationTrader'

import { Carousel,Button ,Row, Col,Card,Input,Divider,Tabs} from 'antd';
import { MoneyCollectOutlined, AndroidOutlined,SmileOutlined,PayCircleOutlined,TransactionOutlined,RotateRightOutlined } from '@ant-design/icons';

const { TabPane } = Tabs;
class Trader extends React.Component {
    constructor(props){
        super(props)
        this.state={
            value:null
        }
    }
    componentDidMount(){
        document.title="個人入駐"
    }
    render() {
      return (
        <div className=" Trader-page">
            <Carousel  effect="fade" className="Trader-banner">
                    <div className="banner-one">
                        <Row>
                            <Col  md={0} lg={5} >
                                
                            </Col>
                            <Col  md={24} lg={5} >
                                <div className="fz22" style={{padding:'10px'}}>成為XPay交易員，離財富自由更近壹點
                                </div>
                                <br></br>
                                <p className="fz32" style={{padding:'10px'}}>高收益</p>
                                <p className="fz32" style={{padding:'10px'}}>資金安全</p>
                                <p className="fz32" style={{padding:'10px'}}>工作自由</p>
                            </Col>
                            <Col  md={0} lg={2} >
                                
                            </Col>
                            <Col md={24} lg={12} className="Trader-free">
                                  <img className="trader-bannerimg" src={require('../static/cofs.png')} alt="logo" />
                            </Col>
                        </Row>
                    </div>
             </Carousel>
             <div className="trader-rs common-connect bgf4" >
                        <h4>XPay平臺優勢</h4>                        
                        <div className="site-card-wrapper row-connect ">
                            <Row  >
                            <Col  md={24} lg={8} >
                                <Card 
                                 
                                 className="card-div bgf4"
                                 bordered={false}>
                                    <p> <img  src={require('../static/baozhang.png')}/></p>
                                    <br></br>
                                    
                                    <h3 style={{fontSize: '20px' }}>擔保交易，安全可靠</h3>
                                    <br></br>
                                    <p>帶著手機即可接單，想接就接，任何事情不耽誤</p>
                                </Card>
                            </Col>
                            <Col md={24} lg={8}>
                                <Card 
                                 className="card-div bgf4"
                                 bordered={false}>
                                     <p> <img src={require('../static/fuwu.png')}/></p>
                                     <br></br>
                                     <h3 style={{fontSize: '20px' }}>加入門檻低</h3>
                                     <br></br>
                                     <p>帶著手機即可接單，想接就接，任何事情不耽誤</p>
                                </Card>
                            </Col>
                            <Col md={24} lg={8} >
                                <Card 
                                 className="card-div bgf4"
                                bordered={false}>
                                     <p> <img src={require('../static/shouyigao.png')}/></p>
                                     <br></br>
                                     <h3 style={{fontSize: '20px' }}>高收益，高回報</h3>
                                     <br></br>
                                     <p>帶著手機即可接單，想接就接，任何事情不耽誤</p>
                                </Card>
                            </Col>
                            </Row>
                        </div>
              </div>
              <div className="Different common-connect  Trader-rise" >
                        <h4>交易員收益案例</h4>                        
                        <div className="site-card-wrapper row-connect">
                            <Row  >
                            <Col  md={24} lg={1} >

                            </Col>
                            <Col  md={24} lg={13} >
                                <div style={{padding:'10px'}}>某交易員當天工作8小時，每日接2筆訂單，壹共接單16筆，平均單筆金額1000USDT。日交易總額達16000USDT，每日即可輕松入賬320USDT，（折合2200元）輕松賺取0.4%利潤
                                </div>
                                <br></br>
                               
                                <div style={{padding:'10px'}}>低投入，高回報，讓您在家輕松賺取收益！絕對是您不可錯失的創富機會！</div>
                            </Col>
                            <Col md={24} lg={8}>
                                  <img  src={require('../static/rise.png')} alt="logo" />
                            </Col>
                            </Row>
                        </div>
              </div>
            <ConsultationTrader/>
        </div>
      );
    }
  }

export default Trader;