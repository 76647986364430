import React, { Component } from 'react';
import { Carousel,Button ,Row, Col,Card,Input,Divider,Steps,message} from 'antd';
import './Consultation.scss'
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined ,PropertySafetyOutlined,VerifiedOutlined,DesktopOutlined } from '@ant-design/icons';
import axios from '../public/axios'


const { Meta } = Card;
const { Search } = Input;
const { Step } = Steps;

class Consultation extends React.Component {
    constructor(props){
        super(props)
        this.state={
          loading:false,
          name:'',
          code:''
        }
    }
    conion(value){
      if(this.state.name==''){
        return message.error('请输入您的姓名');
      }
      if(value==''){
        return message.error('请输入您的联系方式');
      }
      let form = {
        type:'WEB_MAIN',
        name: '',
        company: '',
        phone: '',
        email: '',
        chat_type: '微信',
        chat_account: '',
        web: '',
        address: '',
        business: '',
        // scale: '',
        remark: ''
      }
      var email = value
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      if(reg.test(email)){
        form.email = email
      }else{
        form.phone = email
      }
      form.name = this.state.name
      
      this.setState({ loading:true});
      axios.post('/v1/web/reginfo',form).then(res=>{
        this.setState({ loading:false});
          if(res.code==0){
            this.setState({ name: '' });
            this.setState({ code: '' });
             message.success('您的咨询已被记录,请等待我们与您联系');
          }else{
            message.error(res.msg);
          }
      })
      // console.log(form)
    }
    render() {
      return (
        <div>
            <div className="Consultation bgf4  pt-80" >
                        <h4>個人入駐流程</h4>  
                        <Steps className="Consultation-Steps">
                          <Step status="finish" title="註冊賬戶" icon={<UserOutlined />} />
                          <Step status="finish" title="KYC認證 " icon={<VerifiedOutlined />} />
                          <Step status="finish" title="預存保證金" icon={<PropertySafetyOutlined />} />
                          <Step status="finish" title="開始接單" icon={<DesktopOutlined />} />
                        </Steps>
                       
                        <br></br>
                        <br></br>
                        <p className="Consultation-p">
                        <Input placeholder="姓名" className="Consultation-name"
                        value={this.state.name}
                        onChange={(e)=> this.setState({ name: e.target.value })}
                        />
                        <Search
                        className="Consultation-Search"
                        placeholder="郵箱或手機"
                        enterButton="提交"
                        size="large"
                        value={this.state.code}
                        onChange={(e)=> this.setState({ code: e.target.value })}
                        onSearch={value => this.conion(value)}
                        loading={this.state.loading}
                        />
                        </p>
                 </div>
        </div>
          );
        }
      }
    
export default Consultation;