
import React, { Component } from 'react';
import { List, Avatar ,Row, Col,Tabs,message,Input,Button,Modal} from 'antd';
import copy from 'copy-to-clipboard';
import axios from '../public/axios'
import './Withdraw.scss'
const { TabPane } = Tabs;
const { Search } = Input;
class Withdraw extends React.Component {
    constructor(props){
        super(props)
        this.state={
            symbol:'ETH_USDT',
            loadings:[],
            address:'',
            number:'',
            elnumber:0,
            visible:false,
            userinfo:{},
            phentervalue:'獲取驗證碼', //驗證碼
            elentervalue:'獲取驗證碼', //驗證碼
            phonecode:'',
            emailcode:'',
            goolecode:'',
            phonetimer:'',
            emailtimer:'',
            phonetimer:null,
        }
    }
    componentDidMount(){
        document.title="提幣"
        axios.get('/v1/account/info').then(res=>{
            if(res.code==0){
                this.setState({ userinfo:res.data })
            }
        })
    }
     onChange = (e,index) => {
        let value = e.target.value
        var reg = /^[0-9]+.?[0-9]*$/;
        if (reg.test(value)) {
            let tlnumber = value.toString().match(/^\d+(?:\.\d{0,4})?/)[0]
            this.setState({ number:tlnumber })
            let elnumber =  tlnumber - index
            if(elnumber<=0){
                this.setState({ elnumber:0})
            }else{
                this.setState({ elnumber:elnumber})
            }
        }else{
            this.setState({ number:''})
            this.setState({ elnumber:0})
        }
        
      };
      
      enterLoading = index =>{
       
        if(this.state.symbol=='ETH_USDT'){
            if(/^0x[0-9a-fA-F]{38,69}$/.test(this.state.address)){

            }else{
                return   message.error('請輸入正確的ERC20提現地址');
            }
        }else if(this.state.symbol=='USDT_TRC20'){
            if(/^T.{20,40}$/.test(this.state.address)){

            }else{
                return   message.error('請輸入正確的TRC20提現地址');
            }
        }else{
            if(/^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,69}$/.test(this.state.address)){

            }else{
                return   message.error('請輸入正確的OMNI提現地址');
            }
        }
        if(this.state.number<10){
            return   message.error('最小提幣數量為10.00USDT');
        }
        this.setState({
            visible: true,
          });
        // console.log(this.state.address,this.state.number,this.state.symbol);
      }
      Tabschange(e){
            this.setState({ number:'' })
            this.setState({ elnumber:0})
            this.setState({ address:'' })
            this.setState({ phonecode:'' })
            this.setState({ emailcode:'' })
            this.setState({ goolecode:'' })
          if(e=='1'){
            this.setState({ symbol:'ETH_USDT'})
          }else if(e=='2'){
            this.setState({ symbol:'USDT_TRC20'})
          }else{
            this.setState({ symbol:'BTC_USDT'})
          }
       
      }
      handleonSearchone = () =>{
            if(this.state.phentervalue!=='獲取驗證碼'){
                return
            }
            const newLoadings = [...this.state.loadings];
            newLoadings[1] = true;
            this.setState({
                 loadings: newLoadings,
            });
            axios.get('/v1/verify/send_phone_code',{
                    
            }).then(res=>{
                newLoadings[1] = false;
                this.setState({ loadings: newLoadings });
                 if(res.code==0){
                    this.changenumberone()
                    message.success('發送成功');
                 }else{
                    message.error(res.msg);
                 }
            })
      }
      handleonSearchtwo = () =>{
        if(this.state.elentervalue!=='獲取驗證碼'){
            return
        }
        const newLoadings = [...this.state.loadings];
        newLoadings[2] = true;
        this.setState({
             loadings: newLoadings,
        });
        axios.get('/v1/verify/send_mail_code',{
                   
        }).then(res=>{
            newLoadings[2] = false;
            this.setState({ loadings: newLoadings });
            if(res.code==0){
                this.changenumbertwo()
                message.success('發送成功');
             }else{
                message.error(res.msg);
             }
        })
  }
      changenumberone= () =>{
        let num = 60;
        let timer =   setInterval(() => {
            num--;
            this.setState({ phentervalue: num });
            if(num<=0){
                clearInterval(timer)
                this.setState({ phentervalue:'獲取驗證碼' });
            }
        }, 1000);
        this.setState({ phonetimer: timer });
      }
      changenumbertwo= () =>{
        let num = 60;
        let timer =   setInterval(() => {
            num--;
            this.setState({ elentervalue: num });
            if(num<=0){
                clearInterval(timer)
                this.setState({ elentervalue:'獲取驗證碼' });
            }
        }, 1000);
        this.setState({ emailtimer: timer });
     }
    componentWillUnmount(){
        clearInterval(this.state.phonetimer);
        clearInterval(this.state.emailtimer);
    }
    submit(){
        if(this.state.phonecode==''){
            return   message.error('請輸入手機驗證碼');
        }
        if(this.state.emailcode==''){
            return   message.error('請輸入郵箱驗證碼');
        }
        if(this.state.goolecode==''){
            return   message.error('請輸入谷歌驗證碼');
        }
        let obj = {
            data:{
                "value":this.state.number,
                "address":this.state.address,
                "symbol":this.state.symbol
            },
            validata:{
                "phoneCode":this.state.phonecode,
                "emailCode":this.state.emailcode,
                "googleCode":this.state.goolecode,
            }
        }
        const newLoadings = [...this.state.loadings];
        newLoadings[0] = true;
        this.setState({
        loadings: newLoadings,
        });
       axios.post('/v1/wallet/withdraw',obj).then(res=>{
        newLoadings[0] = false;
        this.setState({ loadings: newLoadings });
           if(res.code==0){
                this.setState({ number:'' })
                this.setState({ address:'' })
                this.setState({ elnumber:0})

                this.setState({ phonecode:'' })
                this.setState({ emailcode:'' })
                this.setState({ goolecode:'' })
                this.setState({ phentervalue:'獲取驗證碼' });
                this.setState({ elentervalue:'獲取驗證碼' });
                clearInterval(this.state.phonetimer);
                clearInterval(this.state.emailtimer);

                this.setState({ visible:false })
                message.success('提現已申請');
           }else{
                message.error(res.msg);
           }
       })
        console.log(obj);
    }
    render() {
        const { loadings,number,elnumber,address } = this.state;
      return (
        <div className="user-l-p Withdraw-usdt">
            <Row>
                <Col  md={24} lg={12} >
                <p className="usdt-pl60"><b>提幣  </b></p>
                 <Tabs   type="card"  onChange={(e)=>{this.Tabschange(e)}}>
                         <TabPane tab="ERC20" key="1" >
                            <br></br>
                            <Input className="h40-mb" addonBefore="請輸入提幣地址" placeholder="請輸入ERC20提幣地址" onChange={(e)=> this.setState({ address: e.target.value })} value={address} defaultValue="" />
                            <Input className="h40-mb" addonBefore="請輸入提幣數量"  placeholder="請輸入ERC20提幣數量" onChange={(e)=>this.onChange(e,3)} value={number} defaultValue="" />
                            <Input className="h40-mb" addonBefore="網絡轉賬費：" disabled defaultValue="3" />
                            <Input className="h40-mb" addonBefore="實際到賬數量：" disabled value={elnumber}   />
                             <p className="te-c"><Button type="primary" className="btn" loading={loadings[0]} onClick={() => this.enterLoading(0)}>
                              提幣
                             </Button></p>
                         </TabPane>
                        <TabPane tab="TRC20" key="2">
                            <br></br>
                            暫未開通
                            {/* 
                            <Input className="h40-mb" addonBefore="請輸入提幣地址" placeholder="請輸入TRC20提幣地址" onChange={(e)=> this.setState({ address: e.target.value })}  value={address} defaultValue="" />
                            <Input className="h40-mb" addonBefore="請輸入提幣數量" placeholder="請輸入TRC20提幣數量" onChange={(e)=>this.onChange(e,1)} value={number} defaultValue="" />
                            <Input className="h40-mb" addonBefore="網絡轉賬費：" disabled defaultValue="1" />
                            <Input className="h40-mb" addonBefore="實際到賬數量：" disabled value={elnumber}   />
                             <p className="te-c"><Button type="primary" className="btn" loading={loadings[0]} onClick={() => this.enterLoading(0)}>
                              提幣
                             </Button></p> */}
                        </TabPane>
                        <TabPane tab="OMNI" key="3">
                        <br></br>
                            <Input className="h40-mb" addonBefore="請輸入提幣地址" placeholder="請輸入OMNI提幣地址" onChange={(e)=> this.setState({ address: e.target.value })} value={address} defaultValue="" />
                            <Input className="h40-mb" addonBefore="請輸入提幣數量" placeholder="請輸入OMNI提幣數量" onChange={(e)=>this.onChange(e,5)} value={number} defaultValue="" />
                            <Input className="h40-mb" addonBefore="網絡轉賬費：" disabled defaultValue="5" />
                            <Input className="h40-mb" addonBefore="實際到賬數量：" disabled value={elnumber}   />
                             <p className="te-c"><Button type="primary" className="btn" onClick={() => this.enterLoading(0)}>
                              提幣
                             </Button></p>
                        </TabPane>
                       
                    </Tabs>
                </Col>
                <Col  md={24} lg={12} >
                <p className="usdt-pl60"><b>提示：  </b></p>
                <List itemLayout="horizontal"  type="card" style={{'paddingRight':'30px'}}>
                     <List.Item>
                               <List.Item.Meta title="1、最小提幣數量為10.00USDT"  />
                     </List.Item>
                     <List.Item>
                               <List.Item.Meta title="2、為確保您的資金安全，建議您於（上午08：00-淩晨2：00間）申請提幣，其他時間提幣將有所延緩。"  />
                     </List.Item>
                     <List.Item>
                               <List.Item.Meta title="3、請務必確認電腦及瀏覽器安全，防止信息被篡改或泄露"  />
                     </List.Item>
                     <List.Item>
                               <List.Item.Meta title="4、大額提現可能會由於跨鏈轉換原因，延時到賬，預計需要1-6個小時"  />
                     </List.Item>
                     <List.Item>
                               <List.Item.Meta title="如有疑問，請 聯系客服"  />
                     </List.Item>
                </List>
                </Col>
            </Row>
            <Modal
                centered
                title="提幣"
                visible={this.state.visible}
                footer={null}
                maskClosable
                onOk={this.handleOk}
                onCancel={()=>{this.setState({ visible:false });}}
                >
                <p >手機號：{this.state.userinfo.mobile}</p>
                <p><Search loading={loadings[1]}
                    placeholder="請輸入手機驗證碼"
                    enterButton={this.state.phentervalue}
                    size="large"
                    value={this.state.phonecode}
                    onSearch={()=>{this.handleonSearchone(1)}}
                    onChange={(e)=> this.setState({ phonecode: e.target.value })}
                    /></p>
                <p>郵箱：{this.state.userinfo.email}</p>
                <p><Search loading={loadings[2]}
                    placeholder="請輸入郵箱驗證碼"
                    enterButton={this.state.elentervalue}
                    size="large"
                    value={this.state.emailcode}
                    onSearch={()=>{this.handleonSearchtwo(2)}}
                    onChange={(e)=> this.setState({ emailcode: e.target.value })}
                 /></p>
                <p><Input loading={loadings[3]}
                    placeholder="請輸入谷歌驗證碼"
                    size="large"
                    value={this.state.goolecode}
                    onChange={(e)=> this.setState({ goolecode: e.target.value })}
                 /></p>
                 <p>
                     <Button style={{'width':'100%'}} onClick={()=>{this.submit()}} type="primary" className="btn" loading={loadings[0]} >
                              提幣
                     </Button>
                 </p>
            </Modal>
        </div>
      );
    }
  }

export default Withdraw;