import React, { Component } from 'react';
import { Layout, Menu, Breadcrumb,  } from 'antd';
import Icon from '@ant-design/icons';
import { BrowserRouter as Router, Route, Link,withRouter } from "react-router-dom";
import Login from '../login/login';
import Echart from '../App';
// import Parent from './Parent';
// import Child from './Child';
// import Tab from './Tab';
// import Nav from './Nav';
// import About from './About';
// import News from './News';
// import Add from './Add';
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
class Laytest extends Component {
  constructor(props) {
    super(props);
    //react定义数据
    this.state = {
    }
  }
  render() {
    return (
      <Router>
        <Layout>
          <Header className="header">
            <div className="logo" />
            <Menu
              theme="dark"
            //   selectedKeys={[this.props.history.location.pathname]}
              mode="horizontal"
              defaultSelectedKeys={[this.props.history.location.pathname]}
              style={{ lineHeight: '54px' }}
            >
                    <Menu.Item key="/index" ><Link to="/index">{this.props.history.location.pathname}</Link></Menu.Item>
                    <Menu.Item key="/tab"><Link to="/tab">云服务</Link></Menu.Item>
                    <Menu.Item key="/child"><Link to="/child">联系我们</Link></Menu.Item>
            </Menu>
          </Header>
          <Layout>
            <Sider width={200} style={{ background: '#fff' }}>
              <Menu
                mode="inline"
                
                defaultSelectedKeys={['9']}
                defaultOpenKeys={[this.props.history.location.pathname]}
                style={{ height: '100%', borderRight: 0 }}
                
              >
                <SubMenu
                  key="/index"
                  title={
                    <span>
                      <Icon type="user" />
                      图表相关
                      </span>
                  }
                >
                  <Menu.Item key="1"> <Link to="/child">统计图</Link></Menu.Item>
                  <Menu.Item key="2"> <Link to="/child">table统计图</Link></Menu.Item>
                  <Menu.Item key="3"><Link to="/child">树形菜单</Link></Menu.Item>

                </SubMenu>
                <SubMenu
                  key="/child"
                  title={
                    <span>
                      <Icon type="laptop" />
                      数据请求
                      </span>
                  }
                >
                  <Menu.Item key="5"> <Link to="/child">表格</Link></Menu.Item>
                  <Menu.Item key="6"> <Link to="/child">树形控件</Link></Menu.Item>
                  <Menu.Item key="7"><Link to="/tab">Tab</Link></Menu.Item>
                  <Menu.Item key="8"><Link to="/tab">Nav</Link></Menu.Item>
                </SubMenu>
                <SubMenu
                  key="/tab"
                  title={
                    <span>
                      <Icon type="notification" />
                      ant组件
                      </span>
                  }
                >
                  <Menu.Item key="9"><Link to="/tab">轮播图</Link></Menu.Item>
                  <Menu.Item key="10"><Link to="/tab">卡片效果</Link></Menu.Item>
                  <Menu.Item key="11"><Link to="/tab">add</Link></Menu.Item>
                  <Menu.Item key="12">option12</Menu.Item>
                </SubMenu>
              </Menu>
            </Sider>
            <Layout style={{ padding: '0 24px 24px' }}>
              {/* <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
                <Breadcrumb.Item>App</Breadcrumb.Item>
              </Breadcrumb> */}
              <Content
                style={{
                  background: '#fff',
                  padding: 24,
                  margin: 0,
                  minHeight: 280,
                }}
              >
                  {/* {this.props.children} */}
                {/* <Route exact path="/echart" component={Login} /> */}
                {/* <Route exact path="/index/parent" component={Echart} /> */}
                <Route exact path="/child" component={Login} />
                <Route exact path="/tab" component={Echart} />
                <Route exact path="/nav" component={Login} />
                <Route exact path="/news" component={Echart} />
                <Route exact path="/about" component={Login} />
                <Route exact path="/add" component={Echart} />
              </Content>
            </Layout>
          </Layout>
        </Layout>

      </Router>
    )
  }
}
export default withRouter(Laytest);